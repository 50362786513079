import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Container = styled.nav`
  z-index: 100;

  ul {
    display: flex;
    list-style: none;
    padding: 0;
    ${MEDIA.TABLET`
      display: none;
    `};

    li {
      text-transform: uppercase;
      font-size: 1.6rem;

      & + li {
        margin-left: 2rem;
      }
    }
  }
`;

export const Hamburger = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: visible;
  margin: 0 -12px 0 0;
  padding: 15px;
  cursor: pointer;
  transition-timing-function: linear;
  transition-duration: 0.15s;
  transition-property: opacity, filter;
  text-transform: none;
  color: inherit;
  background-color: transparent;
  display: none;
  ${MEDIA.TABLET`
    display: flex
  `};
`;

export const HamburgerBox = styled.div`
  position: relative;
  display: inline-block;
  width: 24px;
  height: 20px;
`;

export const HamburgerInner = styled.div`
  background-color: #757575;
  position: absolute;
  z-index: 11;
  width: 28px;
  height: 2px;
  border-radius: 3px;
  top: 50%;
  left: 0;
  right: 0;
  transition-duration: 0.22s;
  transition-property: transform;
  transition-delay: ${props => (props.menuOpen ? '0.12s' : '0s')};
  transform: rotate(${props => (props.menuOpen ? '225deg' : '0deg')});
  transition-timing-function: cubic-bezier(
    ${props =>
      props.menuOpen ? '0.215, 0.61, 0.355, 1' : '0.55, 0.055, 0.675, 0.19'}
  );
  &:before,
  &:after {
    content: '';
    display: block;
    background-color: #757575;
    position: absolute;
    left: auto;
    right: 0;
    width: 24px;
    height: 2px;
    transition-timing-function: ease;
    transition-duration: 0.15s;
    transition-property: transform;
    border-radius: 3px;
  }
  &:before {
    width: ${props => (props.menuOpen ? '100%' : '100%')};
    top: ${props => (props.menuOpen ? '0' : '-10px')};
    opacity: ${props => (props.menuOpen ? 0 : 1)};
    transition: ${props =>
      props.menuOpen
        ? 'top 0.1s ease-out, opacity 0.1s ease-out'
        : 'top 0.1s ease-in 0.25s, opacity 0.1s ease-in'};
  }
  &:after {
    width: ${props => (props.menuOpen ? '100%' : '100%')};
    bottom: ${props => (props.menuOpen ? '0' : '-10px')};
    transform: rotate(${props => (props.menuOpen ? '-90deg' : '0')});
    transition: ${props =>
      props.menuOpen
        ? 'bottom 0.1s ease-out, transform 0.22s cubic-bezier(0.215, 0.61, 0.355, 1) 0.12s'
        : 'bottom 0.1s ease-in 0.25s, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19)'};
  }
`;

import React from 'react';
import PropTypes from 'prop-types';
import {
  MenuContainer,
  Sidebar,
  NavLink,
  NavLinks,
  NavList,
  NavListItem,
} from './menu.css';
import pdf from '../../../../static/blastFoam_User_Guide.pdf';

const Menu = ({ menuOpen, toggleMenu }) => {
  const handleMenuClick = e => {
    const target = e.target;
    const isLink = target.hasAttribute('href');
    const isNotMenu =
      target.className && target.className.includes('MenuContainer');

    if (isLink || isNotMenu) {
      toggleMenu();
    }
  };

  return (
    <MenuContainer
      className="MenuContainer"
      menuOpen={menuOpen}
      onClick={handleMenuClick}
      aria-hidden={!menuOpen}
      tabIndex={menuOpen ? 1 : -1}
    >
      <Sidebar>
        <NavLinks>
          <NavList>
            <NavListItem>
              <NavLink to="/examples">Examples</NavLink>
            </NavListItem>
            <NavListItem>
              <NavLink to="/documentation">Documentation</NavLink>
            </NavListItem>
            <NavListItem>
              <a href={pdf}>User Guide</a>
            </NavListItem>
            <NavListItem>
              <NavLink to="/contact">Contact</NavLink>
            </NavListItem>
            <NavListItem>
              <a href="https://github.com/synthetik-technologies/blastfoam">
                GitHub
              </a>
            </NavListItem>
          </NavList>
        </NavLinks>
      </Sidebar>
    </MenuContainer>
  );
};

Menu.propTypes = {
  menuOpen: PropTypes.bool.isRequired,
  toggleMenu: PropTypes.func.isRequired,
};

export default Menu;

import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';
import { Link } from 'gatsby';

export const MenuContainer = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  outline: 0;
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  transform: translateX(${props => (props.menuOpen ? 0 : 100)}vw);
  visibility: ${props => (props.menuOpen ? 'visible' : 'hidden')};
  display: none;
  ${MEDIA.TABLET`
        display: block;
    `}
`;

export const Sidebar = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: white;
  opacity: 1;
  padding: 100px 0px 0px 0px;
  width: 63vw;
  height: 100%;
  position: relative;
  right: 0;
  margin-left: auto;
  box-shadow: -10px 0px 30px -15px #c5c5c5;
`;

export const NavLinks = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-direction: column;
  z-index: 50;
`;

export const NavList = styled.ol`
  width: 100%;
`;

export const NavListItem = styled.li`
  margin-bottom: 4px;
  padding: 15px 25px;
  position: relative;
  font-size: 24px;
  background-color: lightgray;
`;

export const NavLink = styled(Link)`
  transition: all0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  display: inline-block;
  text-decoration: none;
  text-decoration-skip-ink: auto;
  color: inherit;
  position: relative;
`;

import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Container = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4rem;
  ${MEDIA.TABLET`
    padding: 2rem;
  `}

  a {
    color: #757575;
    transition: color 0.2s ease;
    text-decoration: none;

    &:hover {
      color: inherit;
    }
  }
`;

export const Icon = styled.img`
  margin-right: 7px;
  margin-bottom: -3px;
  height: 30px;
  width: auto;
`;

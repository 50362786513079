import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { Container, Hamburger, HamburgerBox, HamburgerInner } from './nav.css';
import Menu from '../menu';
class Nav extends Component {
  state = {
    menuOpen: false,
  };
  toggleMenu = () => {
    this.setState({ menuOpen: !this.state.menuOpen });
  };

  render() {
    const { menuOpen } = this.state;
    return (
      <>
        <Container>
          <ul>
            <li>
              <Link to="/examples">Examples</Link>
            </li>
            <li>
              <Link to="/documentation">Documentation</Link>
            </li>
            <li>
              <Link to="/contact">Contact</Link>
            </li>
            <li>
              <a href="https://github.com/synthetik-technologies/blastfoam">
                GitHub
              </a>
            </li>
          </ul>
          <Hamburger onClick={this.toggleMenu}>
            <HamburgerBox>
              <HamburgerInner menuOpen={menuOpen} />
            </HamburgerBox>
          </Hamburger>
        </Container>
        <Menu menuOpen={menuOpen} toggleMenu={this.toggleMenu} />
      </>
    );
  }
}

Nav.propTypes = {
  showMenu: PropTypes.func,
  hideMenu: PropTypes.func,
};

export default Nav;

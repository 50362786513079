import styled from 'styled-components';

export const Footer = styled.footer`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: space-around;
  justify-content: center;
  padding: 2rem;
  margin: 0;
  bottom: 0;
  max-width: 100vw;
  height: 100%;
  background-color: white;
  text-align: center;
`;

export const FooterItem = styled.h3`
  padding: 5px;
  font-weight: 400;
`;

import React from 'react';
// import { Link } from 'gatsby';
// import posed from 'react-pose';
// import logo from '../../images/icon.png';
import { Footer, FooterItem } from './footer.css';

const FooterContainer = () => (
  <Footer>
    <FooterItem>&copy; 2019 Synthetik Applied Technologies</FooterItem>
    <FooterItem>701 Brazos Street Austin, TX, 78701</FooterItem>
  </Footer>
);

export default FooterContainer;
